<template>
  <div class="container">
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import { mapState, mapMutations } from 'vuex'
import storage from "../../common/utils/storage";
import BScroll from 'better-scroll'
import MescrollVue from 'mescroll.js/mescroll.vue'
import Sidebar from '../../components/sidebar.vue'

export default {
  name: 'Home',
  data() {
    return {
      scroll: null,
      bannerList: [],
      liveShow: true,
      liveSwiperOptons: {
        pagination:{
          el:'.swiper-pagination',
          clickable:true,
        },
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 10
      },
      showDepartmentPicker: false,
      DepartmentColumns: [],
      tempDepartmentTCcode: '',
      ModuleType: 1,
      LiveList: [],
      list: [],
      PageIndex: 0,
      PageSize: 8,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
      },
      mescrollUp: { // 上拉加载的配置.
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 8 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata">-- END --</p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			},
      sidebarShow: false,
    }
  },
  mixins: [mixin],
  components: {
  },
  computed: {
    ...mapState(['userInfo', 'isLogin', 'homeTipsShow']),
    itemWidth() {
      return (340 * 0.5 * (document.documentElement.clientWidth / 375))
    },
    gutterWidth() {
      return (20 * 0.5 * (document.documentElement.clientWidth / 375))
    }
  },
  created() {
    this.showDepartment();
  },
  mounted() {
    this.initBScroll();
  },
  activated(){
    this.mescroll.scrollTo(this.mescroll.preScrollY, 0);
    this.$store.commit('changeNavbarIndex', 0);
  },
  methods: {
    ...mapMutations(['changeHomeTipsShow']),
    // 初始化BScroll
    initBScroll() {
      this.scroll = new BScroll(this.$refs['wrapper'], {
        startX: 0,
        click: true,
        scrollX: true,
        scrollY: false,
        eventPassthrough: 'vertical'
      })
    },
    // 显示科室选中picker框
    showDepartment() {
      let Token = storage.getStore("Token");
      if (!this.isLogin) {
        this.$dialog.confirm({
          title: '提示',
          message: '您当前还未注册，请先去注册',
        }).then(() => {
          this.$router.push('/entrance');
        })
      } else if (!Token) {
        this.$dialog.alert({
          title: '提示',
          message: '您当前还未登录，请先去登录',
        }).then(() => {
          this.$router.push('/login');
        })
      } else {
        window.location.href= process.env.VUE_APP_BASE_URL + "/xinxiong/index.html?customerid=" + this.userInfo.Id;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  // padding: 16px 0 56px;
  box-sizing: border-box;
  background-color: #F9F9F9;
  .containerBox {
    background-image: url('../../assets/images/bg_comm_png@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 16px;
  }
  .mescroll {
    position: fixed;
    top: 0;
    bottom: 60px;
    height: auto;
  }
  .top {
    margin-bottom: 10px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .classify {
    display: flex;
    color: #FFFFFF;
    height: 31px;
    padding: 0 12px;
    .classify_content {
      display: flex;
      flex: 1;
      font-size: 14px;
      overflow: hidden;
      .classify_wrapper {
        white-space: nowrap;
        span {
          position: relative;
        }
        span:not(:last-child) {
          margin-right: 20px;
        }
        .active_classify {
          font-size: 18px;
          font-weight: bold;
        }
        .active_classify::after {
          content: "";
          width: 22px;
          height: 3px;
          border-radius: 3px;
          background-color: #FFFFFF;
          position: absolute;
          top: 25px;
          left: 50%;
          transform: translateX(-11px);
        }
      }
    }
    .more {
      font-size: 12px;
      margin-left: 10px;
      a {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        margin-top: 6px;
      }
      .addIcon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      span {
        opacity: 0.8;
      }
    }
  }
  .content {
    min-height: 100%;
    margin-top: 1px;
    padding: 0 12px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
  }
  .content:before{
    content: '';
    display: table;
  }
  .banner {
    // height: 185px;
    margin: 20px 0 15px 0;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
    overflow: hidden;
    .bannerImg {
      width: 100%;
      display: block;
    }
  }
  .live_conference {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    margin-top: 15px;
    .live_title {
      display: flex;
      justify-content: space-between;
      color: #CA001B;
      .live_title_text {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        img {
          width: 12px;
          height: 6px;
          margin-left: 7px;
        }
        .rotate {
          transform: rotate(180deg);
        }
      }
      .live_more {
        display: flex;
        align-items: center;
        font-size: 12px;
        img {
          width: 12px;
          height: 12px;
          margin-left: 4px;
        }
      }
    }
    .live_content {
      position: relative;
      height: 90px;
      margin-top: 10px;
      overflow: hidden;
      .live_cover {
        width: 100%;
        height: 76px;
      }
      .live_status {
        position: absolute;
        top: 5px;
        left: 5px;
        display: inline-block;
        height: 15px;
        line-height: 15px;
        border-radius: 15px;
        padding: 0 6px;
        font-size: 9px;
        color: #FFFFFF;
        background-color: #000000;
        opacity: 0.5;
      }
    }
  }
  .home_classify {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .home_classify_content {
      display: flex;
      .home_classify_item {
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 30px;
        padding: 0 10px;
        margin-right: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #CA001B;
        border:  1px solid rgba(202, 0, 27, 0.5);
        .screenIcon {
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
      .home_classify_item_active {
        color: #FFFFFF;
        background-color: #CA001B;
        border: none;
      }
    }
    .screenIcon {
      width: 30px;
      height: 30px;
    }
    .screenTips {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      right: -2px;
      bottom: 40px;
      z-index: 99;
      width: 66%;
      height: 30px;
      line-height: 30px;
      border-radius: 10px;
      padding: 0 15px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.75);
      font-size: 12px;
      color: #FFFFFF;
      img {
        width: 10px;
        height: 10px;
      }
    }
    .screenTips:before{
      content: "";
      width: 0px;
      height: 0px;
      border-top: 8px solid rgba(0, 0, 0, 0.75);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      top: 30px;
      right: 9px;
    }
  }
  .picker_content {
    padding: 20px 6px;
    .pickerBox {
      display: flex;
      flex-wrap: wrap;
    }
    .picker_block {
      width: calc(calc(100% / 3) - 12px);
      height: 36px;
      line-height: 36px;
      text-align: center;
      border: 1px solid rgba(153, 153, 153, 0.5);
      box-sizing: border-box;
      margin: 6px;
      border-radius: 4px;
      color: #333333;
    }
    .activeBg {
      border: none;
      color: #C70C3C;
      background-image: url('../../assets/images/select_system_btn@2x.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .list {
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    .item_img {
      position: relative;
      height: 90px;
      overflow: hidden;
      .item_cover {
        display: block;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .playIcon {
        position: absolute;
        right: 12px;
        bottom: 12px;
        width: 30px;
        height: 30px;
      }
    }
    .item_content {
      padding: 5px 8px 8px;
      .item_title {
        height: 38px;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        word-wrap: break-word;
      }
      .item_time {
        font-size: 12px;
        color: #999999;
        line-height: 24px;
      }
      .item_label {
        display: flex;
        flex-wrap: wrap;
        span {
          display: inline-block;
          height: 20px;
          line-height: 20px;
          border-radius: 2px;
          padding: 0 6px;
          margin-right: 6px;
          margin-bottom: 5px;
          background-color: rgba(255, 73, 98, 0.15);
          font-size: 11px;
          color: #CA001B;
        }
      }
      .item_nums {
        display: flex;
        justify-content: flex-end;
        .nums {
          display: flex;
          align-items: center;
          img {
            width: 12px;
            margin: 0 2px 0 6px;
          }
          span {
            font-size: 11px;
            color: #666666;
          }
        }
      }
    }
  }
}

/deep/ .swiper-pagination-bullet-active {
  background-color: #CA001B !important;
}

/deep/ .swiper-pagination-bullet {
  width: 10px;
  height: 3px;
  border-radius: 3px;
  background-color: #999999;
  margin: 0;
}

/deep/ .swiper-pagination-bullets {
  bottom: 0px;
}

/deep/ .van-swipe__indicators {
  top: 90%;
}
</style>